import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import _ from 'lodash';
import {ApiContext} from '^contexts/api';
import {isResponseError} from '^utilities/isResponseError';
import {Button, Col, Form, Row, Spinner} from 'react-bootstrap';
import UnitAssetCapture from '^pages/workTicket/units/unitAssetCapture';
import {toast} from 'react-toastify';

const SerialInput = ({
    workTicket,
    part,
    stage,
    resource,
    setParentDisabled,
    filterStages,
    serialInputRef,
    partInputRef,
    setConfigId,
}) => {
    const api = useContext(ApiContext);
    const assetCaptureRef = useRef(null);

    const [unit, setUnit] = useState(null);
    const [serial, setSerial] = useState('');

    const [processing, setProcessing] = useState(0);
    const [clearPending, setClearPending] = useState(false);

    const workTicketId = workTicket?.work_ticket_id;
    const configId = part?.config_id;
    const resourceId = resource?.resource_id;
    const unitId = unit?.unit_id;

    const clearForm = useCallback(() => {
        setUnit(null);
        setSerial('');
        setProcessing(0);
        setClearPending(false);

        setTimeout(() => {
            setConfigId('');
            partInputRef?.current?.focus?.();
        }, 100);
    }, [partInputRef, setConfigId]);

    useEffect(() => {
        if (clearPending && !processing) {
            clearForm();
        }
    }, [clearForm, clearPending, processing]);

    useEffect(() => {
        setParentDisabled(!!processing || !!unit);
    }, [processing, setParentDisabled, unit]);

    const updateSerial = useCallback(async (serial) => {
        if (_.isEmpty(_.trim(serial))) {
            return;
        }

        setProcessing((prevState) => prevState + 1);
        const assignSerialResponse = await api.post(
            `/work-tickets/${workTicketId}/unit-serial-number`,
            {
                serial_number: serial,
                config_id: configId,
                filter_stage: filterStages,
            },
        );

        if (isResponseError(assignSerialResponse)) {
            toast.error(assignSerialResponse?.data?.error);
            setProcessing((prevState) => prevState - 1);
            return;
        }

        const unitId = assignSerialResponse?.data?.unit_id;

        const updateUnitResponse = await api.patch(`/units/${unitId}`, {
            stage: stage,
            resource: {resource_id: resourceId},
        });

        if (isResponseError(updateUnitResponse)) {
            toast.error(updateUnitResponse?.data?.error);
            setProcessing((prevState) => prevState - 1);
            return;
        }

        setUnit(updateUnitResponse?.data);
        setSerial(updateUnitResponse?.data?.serial_number);
        setProcessing((prevState) => prevState - 1);

        const firstAssetDataInput = assetCaptureRef?.current
            ?.querySelector?.('input');

        if (firstAssetDataInput) {
            firstAssetDataInput.focus();
        } else {
            clearForm();
        }
    }, [
        api,
        clearForm,
        configId,
        filterStages,
        resourceId,
        stage,
        workTicketId,
    ]);

    const setAssetCaptureField = useCallback(async (field, value) => {
        if (!unitId) {
            return;
        }

        setProcessing((prevState) => prevState + 1);

        const oldVal = unit?.asset_capture?.[field];

        setUnit((prevState) => ({
            ...prevState,
            asset_capture: {
                ...prevState?.asset_capture ?? {},
                [field]: _.trim(value),
            },
        }));

        const response = await api.patch(`/units/${unitId}`, {
            asset_capture: {[field]: value},
        });

        if (isResponseError(response)) {
            toast.error(response?.data?.error);

            setUnit((prevState) => ({
                ...prevState,
                asset_capture: {
                    ...prevState.asset_capture,
                    [field]: oldVal,
                },
            }));

            setProcessing((prevState) => prevState - 1);
            return;
        }

        setProcessing((prevState) => prevState - 1);
    }, [api, unit?.asset_capture, unitId]);

    return <>
        <Row className={'align-items-end mb-2'}>
            <Col>
                <Form.Group>
                    <Form.Text>{'Serial Number*'}</Form.Text>
                    <Form.Control
                        ref={serialInputRef}
                        disabled={!!processing || !!unit}
                        type={'text'}
                        value={serial}
                        onChange={(e) => setSerial(e.target.value)}
                        onKeyDown={(e) => e.key === 'Enter' && e.target.blur()}
                        onBlur={(e) => updateSerial(e.target.value)}
                    />
                </Form.Group>
            </Col>
            <Col xs={1} className={'mb-2'}>
                {!!processing && <Spinner size={'sm'}/>}
            </Col>
            <Col xs={4}>
                <Button
                    disabled={!!processing}
                    className={'w-100'}
                    onPointerDown={(e) => {
                        e.preventDefault();
                    }}
                    onClick={clearForm}
                >
                    {'Clear Form'}
                </Button>
            </Col>
        </Row>
        <UnitAssetCapture
            ref={assetCaptureRef}
            assetCapture={unit?.asset_capture ?? {}}
            setAssetCaptureField={setAssetCaptureField}
            onLastBlur={() => setClearPending(true)}
        />
    </>;
};

export default SerialInput;
