import React, {useCallback, useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import {ApiContext} from '^contexts/api';
import {isResponseError} from '^utilities/isResponseError';
import {Button, Col, Form, Row, Spinner} from 'react-bootstrap';
import {toast} from 'react-toastify';

const AllButton = styled.div.attrs(() => ({
    role: 'button',
}))`
    font-size: 0.8rem;
`;

const QtyInput = ({
    workTicket,
    part,
    stage,
    resource,
    setParentDisabled,
    filterStages,
}) => {
    const api = useContext(ApiContext);

    const [units, setUnits] = useState([]);
    const [selectedUnits, setSelectedUnits] = useState([]);
    const [qtyValues, setQtyValues] = useState({});
    const [actionableCount, setActionableCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [processing, setProcessing] = useState(false);

    const workTicketId = workTicket?.work_ticket_id;
    const configId = part?.config_id;
    const resourceId = resource?.resource_id;
    const resourceLabel = resource?.label ?? 'N/A';

    useEffect(() => {
        setParentDisabled(processing);
    }, [processing, setParentDisabled]);

    const availableUnits = _.groupBy(
        units,
        (unit) => `${unit.stage}|${unit.resource?.label ?? 'N/A'}`,
    );

    useEffect(() => {
        const findTotalUnits = async () => {
            const response = await api.get('/units', {
                params: {
                    work_ticket_id: workTicketId,
                    config_id: configId,
                    stage: _.join(filterStages, ','),
                },
            });

            if (isResponseError(response)) {
                return;
            }

            setTotalCount(response.data?.total_count);
        };

        if (api) {
            findTotalUnits();
        }
    }, [api, filterStages, configId, workTicketId]);

    useEffect(() => {
        const findUnits = async () => {
            let nextOffset = null;
            setProcessing(true);
            setUnits([]);

            do {
                const response = await api.get('/units', {
                    params: {
                        work_ticket_id: workTicketId,
                        config_id: configId,
                        kit_id: '',
                        assembled_into_unit_id: '',
                        stage: _.join(filterStages, ','),
                        offset: nextOffset,
                    },
                });

                if (isResponseError(response)) {
                    nextOffset = null;
                    return;
                }

                setUnits((prevState) => _.uniqBy(
                    [...prevState, ...response.data.results],
                    'unit_id',
                ));

                setActionableCount(response.data?.total_count);
                nextOffset = response.data?.next_offset;
            } while (nextOffset);

            setProcessing(false);
        };

        if (api) {
            findUnits();
        }
    }, [api, filterStages, configId, workTicketId]);

    const selectUnits = useCallback((group, qty) => {
        setQtyValues((prevState) => ({
            ...prevState,
            [group]: qty,
        }));

        setSelectedUnits((prevState) => ({
            ...prevState,
            [group]: _.slice(availableUnits[group], 0, qty),
        }));
    }, [availableUnits]);

    const updateQtyBasedUnits = useCallback(async () => {
        setProcessing(true);

        const unitIds = _.chain(selectedUnits)
            .values()
            .flatten()
            .map('unit_id')
            .value();

        const updateUnitResponse = await api.patch('/units', {
            unit_id: unitIds,
            resource: {resource_id: resourceId},
            stage: stage,
        });

        if (isResponseError(updateUnitResponse)) {
            toast.error(updateUnitResponse?.data?.error);
            setProcessing(false);
            return;
        }

        setProcessing(false);
        setSelectedUnits([]);
        setQtyValues({});

        setUnits((prevState) => _.map(prevState, (unit) => {
            if (_.includes(unitIds, unit.unit_id)) {
                return {...unit, resource, stage};
            }

            return unit;
        }));
    }, [api, resource, resourceId, selectedUnits, stage]);

    return <>
        <Row className={_.join([
            'pt-3',
            'text-primary',
            'border-primary',
            'border-bottom',
            'border-2',
        ], ' ')}>
            <Col xs={3}>{'Qty'}</Col>
            <Col xs={4}>{'Stage'}</Col>
            <Col xs={5}>{'Resource'}</Col>
        </Row>
        {_.map(availableUnits, (units, group) => {
            // Regex matches only the first '|' character in case the
            //  resource label includes it for some reason
            const [oldStage, oldResource] = _.split(group, /\|(.*)/);
            const max = _.size(units);

            const sameStage = oldStage === stage;
            // Valid to use label since it's unique with a facility
            const sameResource = oldResource === resourceLabel;
            const disabled = sameStage && sameResource;

            return <Row key={group} className={'pt-3 text-primary'}>
                <Col xs={3} className={'text-center'}>
                    <Form.Control
                        type={'number'}
                        disabled={disabled}
                        min={0}
                        max={max}
                        value={disabled
                            ? max
                            : qtyValues?.[group] ?? 0}
                        onChange={(e) => {
                            const eventVal = _.toInteger(e.target.value);
                            const newVal = _.min([eventVal, max]);
                            selectUnits(group, newVal);
                        }}
                    />
                    {!disabled && <AllButton
                        onClick={() => selectUnits(group, max)}
                    >
                        {`All (${max})`}
                    </AllButton>}
                </Col>
                <Col xs={4} className={'mt-2'}>{oldStage}</Col>
                <Col xs={5} className={'mt-2'}>{oldResource}</Col>
            </Row>;
        })}
        {actionableCount < totalCount && <Row
            className={'pt-3 align-items-center'}
        >
            <Col className={'text-primary'}>
                {'*One or more kitted/assembled Units not shown'}
            </Col>
        </Row>}
        <Row className={'py-3 align-items-center'}>
            <Col>
                <Button
                    disabled={processing}
                    className={'w-100'}
                    variant={'success'}
                    onClick={updateQtyBasedUnits}
                >
                    {'Submit'}
                </Button>
            </Col>
            <Col xs={2} className={'text-center'}>
                {processing && <Spinner size={'sm'}/>}
            </Col>
        </Row>
    </>;
};

export default QtyInput;
