import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import {ApiContext} from '^contexts/api';
import {isResponseError} from '^utilities/isResponseError';
import AsideHeader from '^common/asideHeader';
import {AsyncFormSelect, FormSelect} from '^common/formSelect';
import {Form} from 'react-bootstrap';
import QtyInput from './qtyInput';
import SerialInput from './serialInput';

const resourceToOption = (resource) => ({
    label: resource?.label,
    value: resource?.resource_id,
});

const EXCLUDE_STAGES = ['Rolled-Back'];

const PartDescriptionContainer = styled.div.attrs(() => ({
    className: 'text-dark text-truncate',
}))`
    min-height: 1.2rem;
    font-size: 0.8rem;
`;

const ManageUnitsAside = ({
    workTicket,
    defaultPart,
}) => {
    const api = useContext(ApiContext);
    const partInputRef = useRef(null);
    const serialInputRef = useRef(null);

    const [disabled, setDisabled] = useState(false);

    const [newStage, setNewStage] = useState('');
    const [stages, setStages] = useState([]);

    const [newResource, setNewResource] = useState('');
    const [resources, setResources] = useState([]);

    const [configId, setConfigId] = useState(defaultPart?.config_id ?? '');
    const [parts, setParts] = useState([]);
    const [partInput, setPartInput] = useState('');

    const workTicketId = workTicket?.work_ticket_id;
    const facilityId = workTicket?.work_ticket_group?.facility?.facility_id;
    const fullPart = _.find(parts, {config_id: configId});
    const fullResource = _.find(resources, {resource_id: newResource});
    const serialized = fullPart?.serialized ?? false;

    const partDescription = useMemo(() => {
        return _.chain(parts)
            .find({config_id: configId})
            .get('description')
            .value();
    }, [configId, parts]);

    useEffect(() => {
        const fetchStages = async () => {
            const response = await api.get('/types/stage');

            if (isResponseError(response)) {
                return;
            }

            setStages(_.difference(response?.data, EXCLUDE_STAGES));
        };

        if (api) {
            fetchStages();
        }
    }, [api]);

    const searchResources = useCallback(async (label) => {
        if (!api) {
            return [];
        }

        if (!facilityId) {
            return [];
        }

        const response = await api.get('/resources', {
            params: {
                active: 1,
                facility_id: facilityId,
                ...label ? {label} : {},
            },
        });

        if (isResponseError(response)) {
            return [];
        }

        const resources = response?.data?.results ?? [];

        setResources((prevState) => _.uniqBy(
            [...prevState, ...resources],
            'resource_id',
        ));

        return _.map(resources, resourceToOption);
    }, [api, facilityId]);

    useEffect(() => {
        const fetchBOM = async () => {
            const response = await api.get(`/work-tickets/${workTicketId}/bom`);

            if (isResponseError(response)) {
                return;
            }

            setParts(response?.data);
        };

        if (api) {
            fetchBOM();
        }
    }, [api, workTicketId]);

    const stageOptions = _.map(stages, (stage) => ({
        label: stage,
        value: stage,
    }));

    const resourceOptions = _.map(resources, resourceToOption);

    const partOptions = _.flatMap(parts, (part) => {
        const serialized = part.serialized
            ? '(Serialized)'
            : '(Non-Serialized)';

        return [
            {
                label: `${part.part_number}-${part.config_label} ${serialized}`,
                value: part.config_id,
            },
            ...part.config_barcode
                ? [{
                    label: `${part.config_barcode} ${serialized}`,
                    value: part.config_id,
                }]
                : [],
        ];
    });

    const filteredPartOptions = _.filter(
        partOptions,
        ({label}) => {
            const partInputRegExp = _.escapeRegExp(partInput);
            const regex = new RegExp(`${partInputRegExp} (.+)`);

            return partInput
                ? regex.test(label)
                : false;
        },
    );

    return <>
        <AsideHeader>
            <div>
                <div>{'Manage Units'}</div>
                <h6>{workTicket.work_ticket_number}</h6>
            </div>
        </AsideHeader>
        <div className={_.join([
            'bg-secondary',
            'rounded',
            'p-2',
            'border',
            'border-2',
            'border-primary',
        ], ' ')}>
            <Form.Group className={'mb-2'}>
                <Form.Text>{'New Stage*'}</Form.Text>
                <FormSelect
                    isDisabled={disabled}
                    value={_.find(
                        stageOptions,
                        {value: newStage},
                    ) ?? ''}
                    options={stageOptions}
                    onChange={(e) => setNewStage(e.value)}
                />
            </Form.Group>
            <Form.Group className={'mb-2'}>
                <Form.Text>{'New Resource'}</Form.Text>
                <AsyncFormSelect
                    isClearable={true}
                    backspaceRemovesValue={true}
                    isDisabled={disabled}
                    loadOptions={searchResources}
                    value={_.find(
                        resourceOptions,
                        {value: newResource},
                    ) ?? ''}
                    onChange={(e) => setNewResource(e?.value)}
                />
            </Form.Group>
            <Form.Group>
                <Form.Text>{'SKU*'}</Form.Text>
                <FormSelect
                    ref={partInputRef}
                    openMenuOnFocus={true}
                    isClearable={true}
                    isDisabled={disabled}
                    value={_.find(
                        partOptions,
                        {value: configId},
                    ) ?? ''}
                    options={filteredPartOptions}
                    onInputChange={setPartInput}
                    onChange={(e) => {
                        setConfigId(e?.value);
                        window.requestAnimationFrame(
                            () => serialInputRef?.current?.focus?.(),
                        );
                    }}
                    noOptionsMessage={({inputValue}) => inputValue
                        ? `SKU "${inputValue}" not found`
                        : 'Enter SKU'}
                />
                <PartDescriptionContainer>
                    {partDescription}
                </PartDescriptionContainer>
            </Form.Group>
        </div>
        {(() => {
            const props = {
                workTicket: workTicket,
                part: fullPart,
                stage: newStage,
                resource: fullResource,
                setParentDisabled: setDisabled,
                filterStages: stages,
            };

            switch (true) {
                case !configId || !newStage:
                    return null;
                case serialized:
                    return <SerialInput
                        serialInputRef={serialInputRef}
                        partInputRef={partInputRef}
                        setConfigId={setConfigId}
                        {...props}
                    />;
                default:
                    return <QtyInput {...props}/>;
            }
        })()}
    </>;
};

export default ManageUnitsAside;
